import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { StorageService } from '@wlp/ui-bs-oauth';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IonContent, ModalController, NavController, ToastController } from '@ionic/angular';
import {
  AccountTypeService,
  IPageBase,
  PartnersService,
  ResponsibleMeiService,
  Step,
  StepFlowService,
  UploadService,
} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import * as moment from 'moment';
import { environment } from '../../../../../../../../environments/environment';
import { ModalAlertComponent } from '../../../../modals/modal-alert/modal-alert.component';
import { PersonalAddressModal } from './partner-flow/personal-address/personal-address.modal';
import { PersonalDataModal } from './partner-flow/personal-data/personal-data.modal';
import { PersonalUploadModal } from './partner-flow/personal-upload/personal-upload.modal';

@Component({
  selector: 'ui-t-partners',
  templateUrl: './partners.page.html',
  styleUrls: ['./partners.page.scss'],
})
export class PartnersPage implements OnInit, IPageBase {
  public listPartners: Array<any>;
  public subscribe: any;

  public formAddPartners: FormGroup;
  public errorFormMessages: any;
  public partnerEditId: string;

  public businessPartner: any;
  public readonly: boolean;
  public uploadChecker: Array<{ id: string, upload: boolean }>;

  public indexItem: number;
  public flagPartner: boolean;
  public edit = false;
  public disabledCancel: boolean;
  public slideOpts: any;

  public nextStep: any;
  public manyPeopleQuantity: number;
  openForm: boolean;

  modalSuccess: HTMLIonModalElement;

  @ViewChild('content', { static: false }) content: IonContent;
  public stepsParteners: any;

  constructor(
    private navCtrl: NavController,
    private partnersService: PartnersService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private modalController: ModalController,
    private responsibleMeiService: ResponsibleMeiService,
    private stepsService: StepFlowService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private uploadService: UploadService,
    private accountType: AccountTypeService,
    private storageService: StorageService,
    private prepareFormDevolution: PreparFormDevolutionService,
  ) {
    moment.locale('pt-BR');
  }

  public async ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    await this.loadPartners();
    this.partnersService.initializeBehaviourList();

    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    this.errorFormMessages = this.partnersService.getFormMessageError();

    this.openForm = false;
    this.indexItem = null;
    this.readonly = false;
    this.indexItem = 0;
    this.disabledCancel = true;
    this.listPartners = [];
    this.uploadChecker = [];

    // slide config
    
    if(window.innerWidth > 845){
      this.slideOpts = {
        initialSlide: 0,
        slidesPerView: 2,
        spaceBetween: 0,
        speed: 400,
        center: true,
      };
    }else if(window.innerWidth > 600){
      this.slideOpts = {
        initialSlide: 0,
        slidesPerView: 1.2,
        spaceBetween: 0,
        speed: 400,
        center: true,
      };
    }else{
      this.slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 400,
        center: true,
      };
    }
    
    

    this.applySignatureQuantity();

    this.nextStep = await this.stepsService.getSteps(environment, this.router.url);
    this.preparePartnersDevolution();
  }

  public async applySignatureQuantity() {
    this.manyPeopleQuantity = 0;
    const account = await this.accountType.getAccount();

    this.manyPeopleQuantity = Number(account.companyData.manyPerson);
  }

  public async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.nextStep);
  }

  addPartner(ev?: boolean) {
    this.storageService.set('isRetryOnboarding', false);
    this.indexItem = null;
    this.readonly = false;
    this.edit = false;
    this.openForm = true;

    setTimeout(() => this.content.scrollToBottom(), 500);
  }

  public async editPartner(partner, updatePartnerPercentage: boolean = false) {
    this.storageService.set('isRetryOnboarding', false);
   
    /*Devolução do PARTNER_START_STEP  */
    if (this.nextStep.retryOnboarding != undefined) {      
      let step = this.stepsParteners.find(el => {
        return el.uuidPartner === partner.id;
      });
      
      let partnerStartStep = step.steps.find(step => {
        return step.name === "PARTNER_START";
      })
      
      if(partnerStartStep != undefined) {
        this.loadingService.show();
        this.storageService.set('isRetryOnboarding', true);
        await this.prepareFormDevolution.prepareFormDevolutionPartners(partnerStartStep.fields, this.formAddPartners);
        setTimeout(() => {
          this.loadingService.hide();          
        }, 2000);
      }
    }

    this.edit = true;
    this.openForm = !updatePartnerPercentage;
    this.partnerEditId = partner.id;
    this.formAddPartners = this.partnersService.editForm(partner);
  }

  public async loadPartners() {
    await this.partnersService.updateForm();
    setTimeout(async () => {
      const partners: any = await this.partnersService.getListPartners();

      this.listPartners = partners    
      /* Set no storage os steps devolvidos na listagem */
      this.listPartners.forEach(el => {
        this.storageService.get(el.id).then(res => {
          if (res != undefined && res.steps.length > 0) {
            res.steps.forEach(element => {
              switch (element) {
                case "PERSONAL_DATA":
                  el.isPersonalData = true;
                  break;

                case "PERSONAL_ADDRESS":
                  el.isPersonalAddress = true;
                  break;

                case "PARTNER_START":
                  el.isPersonalStartStep = true;
                  break;
              }
            });
          } else {
            el.isPersonalData = false;
            el.isPersonalAddress = false;
            el.isPersonalStartStep = false;
          }
        })
      })

      this.uploadChecker = [];
      partners.map(async (partner) => {
        const list = await this.uploadService
          .findDocumentsPartnerToUpload(partner.id, '/upload-documents-partner');
        this.uploadChecker.push({ id: partner.id, upload: (list.data.length === 0) });
      });
      this.loadingService.hide();
    }, 1000);
  }

  public async savePartner() {
    this.storageService.set('isRetryOnboarding', true)
    if (await this.findCpfExists() && !this.edit) {
      this.toastError('cpf');
      return;
    }

    if (await this.findEmailExists() && !this.edit) {
      this.toastError('email');
      return;
    }

    if (await this.findPhoneNumberExists() && !this.edit) {
      this.toastError('phone');
      return;
    }

    this.loadingService.show();

    if (this.edit) {
      await this.responsibleMeiService.editPartner(this.formAddPartners.getRawValue(), this.partnerEditId);
      this.listPartners.map(el => {
        if (el.id === this.partnerEditId) el.isPersonalStartStep = false;

        this.storageService.get(this.partnerEditId).then(res => {
          let index = res.steps.indexOf("PARTNER_START");
          if (index > -1) {
            res.steps.splice(index, 1);            
            this.storageService.set(this.partnerEditId, { id: this.partnerEditId, steps: res.steps });
          }
        });

      })
      
    } else {
      await this.responsibleMeiService.sendFormBusinessPartner(this.formAddPartners.getRawValue());
    }

    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    await this.loadPartners();

    this.cancelPartner();
    this.indexItem = null;
    this.edit = false;
  }

  public async removePartner(partnerData: any) {
    const { partnerId, index } = partnerData;

    let mess = 'ONBORDING.V2.TEXT.TITLE.MODAL_REMOVE_PARTNER';
    await this.notification('warning-circle', this.translate.applyTranslate(mess, { 'name': this.listPartners[index].fullname }));

    this.modalSuccess.onDidDismiss().then((callback) => {
      if (callback.data) {
        this.responsibleMeiService.removePartner(partnerId);
        this.listPartners = this.listPartners.filter((arr, i) => index !== i);
        this.uploadChecker = this.uploadChecker.filter((arr, i) => arr.id !== partnerId);

        if (this.edit) {
          this.formAddPartners = this.partnersService.getFormBusinessPartner();
          this.edit = false;
          this.openForm = false;
        }
      }
    });
  }

  public async continue() {
    await this.stepsService.nextStep(this.navCtrl, this.nextStep);
  }

  public async toastError(error?: string) {
    let errorMessage = 'ONBORDING.ADD_PARTNERS.MESSAGES.FAILED';
    if (error === 'cpf') {
      errorMessage = 'ONBORDING.V2.TEXT.TITLE.CPF_EXISTS';
    }
    if (error === 'email') {
      errorMessage = 'ONBORDING.V2.TEXT.TITLE.EMAIL_EXISTS';
    }
    if (error == "phone") {
      errorMessage = 'ONBORDING.V2.TEXT.TITLE.PHONE_NUMBER_EXISTS';
    }
    
    const toast = await this.toastController.create({
      message: this.translate.applyTranslate(errorMessage),
      duration: 3000,
    });
    toast.present();
  }

  public async findCpfExists(): Promise<boolean> {
    const partners = await this.partnersService.getListPartners();
    const results = partners.filter((el) =>
      el.docNumber === this.formAddPartners.get('docNumber').value);

    return (results.length > 0);
  }

  public async findEmailExists(): Promise<boolean> {
    const partners = await this.partnersService.getListPartners();
    const results = partners.filter((data) => 
      data.email === this.formAddPartners.get('email').value)

    return (results.length > 0)
  }

  public async findPhoneNumberExists(): Promise<boolean> {
    const partners = await this.partnersService.getListPartners();
    const results = partners.filter((data) => 
      data.phone === this.formAddPartners.get('phone').value)

    return (results.length > 0)
  }

  public cancelPartner() {
    this.storageService.set('isRetryOnboarding', true)
    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    this.indexItem = null;
    this.edit = false;
    this.openForm = false;
  }

  /**
   * modal de dados pessoais
   * @param partner
   */
  public async modalPersonalData(partner, formUpdate?) {
    let step = undefined;
    
    const filteredPartners = this.listPartners.filter(item => (item.docNumber != partner.docNumber))
    const allPartnersPercentage = filteredPartners.reduce((a, b) => {
      return a.partnerPercentage + b.partnerPercentage
    })

    const totalPercentageAvailable = filteredPartners.length <= 1 ? 100 - allPartnersPercentage.partnerPercentage : 100 - allPartnersPercentage;
    console.log(totalPercentageAvailable)

    if (this.nextStep.retryOnboarding != undefined) {
      step = this.stepsParteners.find(el => {
        return el.uuidPartner === partner.id;
      });
    }
    if (step === undefined) {
      this.storageService.set('isRetryOnboarding', false)
    }
    else this.storageService.set('isRetryOnboarding', true)

    let form = null;
    if (partner.personalData !== undefined) {
      form = partner.personalData

    } else if (!!partner) {
      form = partner;
    }

    const currentPartner = {
      name: partner.fullname,
      types: [
        partner.master,
        partner.attorney,
        partner.legalRepresentative,
        partner.partner,
        partner.signature,
        partner.partnerPercentage
      ],
    };

    const modal = await this.modalController.create({
      component: PersonalDataModal,
      cssClass: 'modal-flow-container-inner',
      componentProps: { 
        partnerId: partner.id, 
        applyForm: form,
        partner: currentPartner,
        step: step,
        partnerToEdit: partner,
        currentRangeValue: partner.partnerPercentage,
        totalPercentageAvailable: totalPercentageAvailable,
        isPartnerTypeSelected: partner.partner,
      },
      backdropDismiss: false,
    });
    await modal.present();

    modal.onWillDismiss()
      .then(async (res) => {
        if (res.data !== null) {
          partner.personalData = res.data.form;
          partner.partnerPercentage = res.data.currentRangeValue
          
          if (partner.partner != "" && partner.partner) {
            this.editPartner(partner, true)
            this.savePartner()
          }

          this.modalAddressData(partner, currentPartner);
        } else {
          await this.loadPartners();
        }
    });

    this.cdr.detectChanges()
  }

  /**
   * modal de endereço
   * @param partner
   * @param currentPartner
   */
  public async modalAddressData(partner, currentPartner) {
    const form = (!!partner.personalAddress) ? partner.personalAddress : null;

    let step: any;
    if (this.nextStep.retryOnboarding != undefined) {
      step = this.stepsParteners.find(el => {
        return el.uuidPartner === partner.id;
      });
    }

    if (step === undefined) {
      this.storageService.set('isRetryOnboarding', false)
    }
    else this.storageService.set('isRetryOnboarding', true)

    const modal = await this.modalController.create({
      component: PersonalAddressModal,
      cssClass: 'modal-flow-container-inner',
      componentProps: { partnerId: partner.id, applyForm: form, partner: currentPartner, step: step },
      backdropDismiss: false,
    });
    await modal.present();

    modal.onWillDismiss()
      .then(async (res) => {
        if (res.data !== null) {
          if (res.data === false) {
            this.modalPersonalData(partner);
          } else {
            partner.personalAddress = res.data;
            this.modalUploadData(partner, currentPartner);
          }
        } else {
          await this.loadPartners();
        }
      });
  }

  /**
   * modal de upload
   * @param partner
   * @param currentPartner
   */
  public async modalUploadData(partner, currentPartner) {
    const partnerId = partner.id;
    const modal = await this.modalController.create({
      component: PersonalUploadModal,
      cssClass: 'modal-partner-upload',
      componentProps: { partnerId, partner: currentPartner },
      backdropDismiss: false,
    });
    await modal.present();
    modal.onWillDismiss()
      .then(async (res) => {
        if (res.data === null) {
          this.modalAddressData(partner, currentPartner);
        } else {
          await this.loadPartners();
        }
      });
  }

  /**
   * retorna o status da etapa de cadastro do socio
   * @param partner
   * @param status
   * @param index
   * @returns
   */
  public checkPartnerStatus(partner, status, index: number) {
    if (status !== 'upload') {
      return (partner[status] !== undefined) ? 'checkbox-outline' : 'square-outline';
    }

    const partnerUpload = this.uploadChecker.filter((arr) => arr.id === partner.id)[0] || null;

    if (partnerUpload === null) { return 'square-outline'; }
    return (partnerUpload.upload) ? 'checkbox-outline' : 'square-outline';
  }

  /**
   * retorna se ainda existem pessoas para serem cadastradas como assinatura
   * @returns
   */
  public getSignatureRemains(): boolean {
    const quantity = this.listPartners.filter((arr) => arr.signature === true).length;
    return quantity >= this.manyPeopleQuantity;
  }

  /**
   * checa se existem etapas que nao foram feitas pelo usuario
   * @returns
   */
  public checkContinueButton(): boolean {
    let isDisabled = false;

    try {
      this.listPartners.forEach((partner, index) => {
        const haveUploaded = this.uploadChecker.find((arr) => arr.id === partner.id);
        if (
          partner.personalData === undefined ||
          partner.personalAddress === undefined ||
          (partner.isPersonalAddress && partner.isPersonalAddress != undefined) ||
          (partner.isPersonalData && partner.isPersonalData != undefined) ||
          !haveUploaded.upload ||
          partner.isPersonalStartStep ||
          !this.getSignatureRemains()) {
          isDisabled = true;
          return;
        }
      });
    } catch (err) {
      // força o botao a ser disabled
      isDisabled = true;
    }

    return isDisabled;
  }

  private async notification(status: string, message: string, confirmation?: boolean) {
    this.modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status,
        message,
        cancelBtn: this.translate.applyTranslate('ONBORDING.V2.BUTTON.CANCEL'),
        confirmBtn: this.translate.applyTranslate('ONBORDING.V2.BUTTON.CONCLUDE'),
        confirmation: true,
        translate: true,
      },
      backdropDismiss: false,
    });

    return await this.modalSuccess.present();

  }

  preparePartnersDevolution() {
    if (this.nextStep.retryOnboarding != undefined) {
      this.stepsParteners = this.nextStep.retryOnboarding.partners;
      this.stepsParteners.forEach(partner => {
        let object = { id: partner.uuidPartner, steps: [] }
        partner.steps.filter(step => {
          object.steps.push(step.name)
        });

        this.storageService.set(object.id, object)
      });
    }
  }
}
