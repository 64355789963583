import { Routes } from '@angular/router';

import { CanAuthenticationGuard } from '../../../core/auth/can-authentication-guard';
import { ThemesComponent } from '../themes.component';
import { RetryOnboardingPage } from './../sentinel/pages/retry-onboarding/retry-onboarding.page';

export const routesCP: Routes = [
  {
    path: '',
    component: ThemesComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
      {
        path: 'welcome',
        loadChildren: () => import('../sentinel/pages/intro/intro.module').then(m => m.IntroPageModule),
      },
      {
        path: 'retry-onboarding',
        // loadChildren: () => import('../sentinel/pages/retry-onboarding/retry-onboarding.module').then(m => m.RetryOnboardingModule),
        component: RetryOnboardingPage
      },
      {
        path: 'app',
        loadChildren: () => import('../sentinel/pages/cp-manager/cp-manager.module').then(m => m.CpManagerModule),
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['cp-client'] },
      },
      {
        path: 'login',
        redirectTo: 'app/dashboard',
      },
      {
        path: 'account-activation',
        loadChildren: () => import('../sentinel/pages/account-ativation/account-ativation.module').then(m => m.AccountAtivationPageModule),
      },

    ],
  },
];
