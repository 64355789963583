import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registry-device',
  templateUrl: './registry-device.page.html',
  styleUrls: ['./registry-device.page.scss'],
})
export class RegistryDevicePage implements OnInit {

  constructor() { }

  ngOnInit() {}

}
