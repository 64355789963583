import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceActionPage } from './device-action/device-action.page';
import { DeviceVeracityPage } from './device-veracity/device-veracity.page';
import { RegistryDevicePage } from './registry-device.page';


const routes: Routes = [
  {
    path: '',
    component: RegistryDevicePage,
    children: [
      {
        path: 'action',
        component: DeviceActionPage,
      },
      {
        path: 'veracity',
        component: DeviceVeracityPage,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistryDeviceRoutingModule {}
