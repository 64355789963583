import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DeviceActionDTO, DeviceStatusEnum, RegisterDeviceDTO, RegistryDeviceService, UserInfoService } from '@wlp/ui-bs-login';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { LayoutConfigService } from '../../../../../../core/layout/services/layout-config.service';
import { ModalAlertComponent } from '../../../modals/modal-alert/modal-alert.component';

@Component({
  selector: 'app-device-action',
  templateUrl: './device-action.page.html',
  styleUrls: ['./device-action.page.scss'],
})
export class DeviceActionPage implements OnInit {
  public navigationBackground: string;
  public brand: string;
  deviceInfo: RegisterDeviceDTO;
  public registeredDeviceForm: FormGroup;
  public selectedValue: string | null = null;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    protected userInfoService: UserInfoService,
    private modalController: ModalController,
    private _registryDeviceService: RegistryDeviceService,
    private _loadingService: LoadingService
  ) { }

  public async ngAfterViewInit() {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
  }

  ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);
    this.registeredDeviceForm = this._registryDeviceService.createFormRegisteredDevice();
    this.getRegisteredDevice();
  }

  async getRegisteredDevice() {
    try {
      this._loadingService.show();
      const res: RegisterDeviceDTO = await this._registryDeviceService.getListDevices().toPromise();
      this.deviceInfo = res;   
      if (res === null || res === undefined) {
        this.modalAlert('warning-circle', 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.MODAL.NO_DEVICE_REGISTERED', false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.hide();
    }
  }

  deviceAction(event: any) {
    let prepare: DeviceActionDTO;
    let deviceStatus: DeviceStatusEnum;

    if (event === 'perdaDoDispositivo') {
      deviceStatus = DeviceStatusEnum.LOSS;
    } else if (event === 'rouboDoDispositivo') {
      deviceStatus = DeviceStatusEnum.THEFT;
    }

    const reasonDescriptionValue = this.registeredDeviceForm.controls.reasonDescription.value || '';

    prepare = {
      uuidUserAccountPaymentDevice: this.deviceInfo.uuid,
      reasonDescription: reasonDescriptionValue,
      deviceStatus: deviceStatus
    };
    this._loadingService.show();
    this._registryDeviceService
      .deviceAction(prepare)
      .pipe(
        tap((data) => {
          this._loadingService.hide();
          this.modalAlert('success', 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.MODAL.SUCCESS_TEXT', false);
        }),
        catchError((_) => {
          this.modalAlert('warning-circle', 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.MODAL.FAILURE_TEXT', false);
          this._loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  onChange(event: any) {
    this.selectedValue = event.detail.value;
  }

  public async modalAlert(status: string, message: string, confirmation?: boolean) {
    this._loadingService.hide();
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: message,
        confirmBtn: 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.BUTTONS.CLOSE',
        confirmation: confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then(() => {      
      this.logout();
    });
    return await modalSuccess.present();
  }

  logout() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;
    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }
    this.userInfoService.onLogout(redirectUrl);
  }

}
