import {ModalBilletJurosComponent} from './modal-billet-juros/modal-billet-juros.component';
import {ModalDetailDualAuth} from './modal-detail-dualauth/modal-detail-dualauth.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {UiBsCardsModule} from '@wlp/ui-bs-cards';
import {UiBsEcModule} from '@wlp/ui-bs-ec';
import {UiBsTokenModule} from '@wlp/ui-bs-token';
import {UiBsTranslateModule} from '@wlp/ui-bs-translate';
import {UiCActiveCardModalFormModule} from '@wlp/ui-c-active-card-modal-form';
import {UiCAddCreditCardFormModule} from '@wlp/ui-c-add-credit-card-form';
import {UiCBilletReviewModule} from '@wlp/ui-c-billet-review';
import {UiCBilletReviewChargingModule} from '@wlp/ui-c-billet-review-charging';
import {UiCButtonModule} from '@wlp/ui-c-button';
import {UiCCardDocumentModule} from '@wlp/ui-c-card-document';
import {UiCCreditCardDisplayModule} from '@wlp/ui-c-credit-card-display';
import {UiCDatePickerModule} from '@wlp/ui-c-date-picker';
import {UiCDeviceModule} from '@wlp/ui-c-device';
import {UiCListDataModule} from '@wlp/ui-c-list-data';
import {UiCModalModule} from '@wlp/ui-c-modal';
import {UiCPaymentExtractModule} from '@wlp/ui-c-payment-extract';
import {UiCPaymentReceiptModule} from '@wlp/ui-c-payment-receipt';
import {UiCPdfGeneratorModule} from '@wlp/ui-c-pdf-generator';
import {UiCSearchBankModule} from '@wlp/ui-c-search-bank';
import {UiCSearchMccModule} from '@wlp/ui-c-search-mcc';
import {UiCSendCodeModule} from '@wlp/ui-c-send-code';
import {UiCSendCodeAccountActivationModule} from '@wlp/ui-c-send-code-account-activation';
import {UiCTextValueModule} from '@wlp/ui-c-text-value';
import {UiCTokenModule} from '@wlp/ui-c-token';
import {UiCTransferReceiptModule} from '@wlp/ui-c-transfer-receipt';
import {UiCFavoritesListModule} from '@wlp/ui-c-favorites-list';
import {UiCInputModule} from '@wlp/ui-c-input';
import {UiCPersonalAddressModule} from '@wlp/ui-c-personal-address';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {QRCodeModule} from 'angularx-qrcode';
import {BrMaskerModule} from 'br-mask';

import {
  PopoverFavoritesComponent,
} from 'src/app/views/themes/sentinel/modals/popover-favorites/popover-favorites.component';
import {environment} from 'src/environments/environment';

import {UiBsDashboardModule} from '@wlp/ui-bs-dashboard';
import {LoadingService} from "../../../../core/layout/services/loading.service";
import {ComponentsModule} from '../../../../core/shared/modules/components.module';
import {ModalAccountActivationComponent} from './modal-account-activation/modal-account-activation.component';
import {ModalActiveCardComponent} from './modal-active-card/modal-active-card.component';
import {
  ModalAlertAccessWebMobileDeviceComponent
} from './modal-alert-access-web-mobile-device/access-web-mobile-device.component';
import {ModalAlertBilletComponent} from './modal-alert-billet/modal-alert-billet.component';
import {ModalAlertCardComponent} from './modal-alert-card/modal-alert-card.component';
import {ModalAlertDocsComponent} from './modal-alert-docs/modal-alert-docs.component';
import {ModalAlertEditComponent} from './modal-alert-edit/modal-alert-edit.component';
import {ModalAlertComponent} from './modal-alert/modal-alert.component';
import {ModalBilletAlertCancelComponent} from './modal-billet-alert-cancel/modal-billet-alert-cancel';
import {ModalBilletEmit} from './modal-billet-emit/modal-billet-emit.component';
import {ModalBilletFavorites} from './modal-billet-favorites/modal-billet-favorites.component';
import {
  ModalBilletReviewChargingComponent
} from './modal-billet-review-charging/modal-billet-review-charging.component';
import {ModalBilletReviewComponent} from './modal-billet-review/modal-billet-review.component';
import {ModalBilletViewDetailV2Component} from './modal-billet-view-detail-v2/modal-billet-view-detail-v2.component';
import {ModalBilletViewDetailComponent} from './modal-billet-view-detail/modal-billet-view-detail';
import {ModalCardDocumentComponent} from './modal-card-document/modal-card-document.component';
import {ModalCardNotificationComponent} from './modal-card-notification/modal-card-notification.component';
import {ModalDetailsDdaComponent} from './modal-details-dda/modal-details-dda.component';
import {ModalExpenseControlComponent} from './modal-expense-control/modal-expense-control.component';
import {ModalExportExtractComponent} from './modal-export-extract/modal-export-extract.component';
import {ModalExtractReviewComponent} from './modal-extract-review/modal-extract-review.component';
import {ModalFavoriteTypeComponent} from './modal-favorite-type/modal-favorite-type.component';
import {ModalFlowComponent} from './modal-flow/modal-flow.component';
import {ModalImportBilletModelComponent} from './modal-import-billet-model/modal-import-billet-model.component';
import {ModalMigrationSuccessComponent} from './modal-migration-success/modal-migration-success.component';
import {ModalMigrationComponent} from "./modal-migration/modal-migration.component";
import {
  ModalOperationNotificationComponent
} from './modal-operation-notification/modal-operation-notification.component';
import {ModalPrivacyPolicyComponent} from './modal-privacy-policy/modal-privacy-policy.component';
import {ModalSearchBankComponent} from './modal-search-bank/modal-search-bank.component';
import {ModalSearchMccComponent} from './modal-search-mcc/modal-search-mcc.component';
import {ModalSharePage} from './modal-share/modal-share.page';
import {ModalTokenChangePasswordComponent} from './modal-token-change-password/modal-token-change-password.component';
import {ModalTokenComponent} from './modal-token/modal-token.component';
import {ModalTrackingProcessComponent} from './modal-tracking-process/modal-tracking-process.component';
import {
  ModalTransferNotificationAcceptedComponent,
} from './modal-transfer-notification-accepted/modal-transfer-notification-accepted.component';
import {
  ModalTransferNotificationCompletedComponent,
} from './modal-transfer-notification-completed/modal-transfer-notification-completed.component';
import {
  ModalTransferNotificationRefusedComponent,
} from './modal-transfer-notification-refused/modal-transfer-notification-refused.component';
import {ModalTransferOnUsComponent} from './modal-transfer-on-us/modal-transfer-on-us.component';
import {ModalTransferResumeComponent} from './modal-transfer-resume/modal-transfer-resume.component';
import {ModalUnlockCardAlertComponent} from './modal-unlock-card-alert/modal-unlock-card-alert.component';
import {ModalUnlockCardComponent} from './modal-unlock-card/modal-unlock-card.component';
import {ModalValidationComponent} from './modal-validation/modal-validation.component';
import {ModalViewFileUpload} from './modal-view-file-upload/modal-view-file-upload.component';
import {ModalsRoutingModule} from './modals-routing.module';
import {ModalAlertBilletTaxesComponent} from './modal-alert-billet-taxes/modal-alert-billet-taxes.component';
import {ModalCnabShippingComponent} from './modal-cnab-shipping/modal-cnab-shipping.component';
import {ModalCnabShippingLineComponent} from './modal-cnab-shipping-line/modal-cnab-shipping-line.component';
import {ModalSplitDetailsComponent} from './modal-split-details/modal-split-details.component';
import {ModalSelfPhotoComponent} from './modal-self-photo/modal-self-photo.component';
import {ModalUnicoValidation} from "./modal-unico-validation/modal-unico-validation.component";

@NgModule({
  declarations: [
    ModalBilletEmit,
    ModalBilletFavorites,
    ModalAlertEditComponent,
    ModalBilletAlertCancelComponent,
    ModalBilletViewDetailComponent,
    ModalAlertComponent,
    ModalSharePage,
    ModalBilletReviewComponent,
    ModalTransferNotificationAcceptedComponent,
    ModalTransferNotificationRefusedComponent,
    ModalTransferNotificationCompletedComponent,
    ModalTransferResumeComponent,
    ModalTokenComponent,
    ModalOperationNotificationComponent,
    ModalSearchBankComponent,
    ModalExtractReviewComponent,
    ModalCardDocumentComponent,
    ModalCardNotificationComponent,
    ModalActiveCardComponent,
    ModalFlowComponent,
    ModalAlertCardComponent,
    ModalUnlockCardAlertComponent,
    ModalUnlockCardComponent,
    ModalBilletReviewChargingComponent,
    ModalAlertBilletComponent,
    ModalAccountActivationComponent,
    PopoverFavoritesComponent,
    ModalSearchMccComponent,
    ModalExpenseControlComponent,
    ModalValidationComponent,
    ModalPrivacyPolicyComponent,
    ModalDetailsDdaComponent,
    ModalViewFileUpload,
    ModalTokenChangePasswordComponent,
    ModalFavoriteTypeComponent,
    ModalTransferOnUsComponent,
    ModalAlertAccessWebMobileDeviceComponent,
    ModalAlertDocsComponent,
    ModalImportBilletModelComponent,
    ModalImportBilletModelComponent,
    ModalMigrationComponent,
    ModalMigrationSuccessComponent,
    ModalDetailDualAuth,
    ModalExportExtractComponent,
    ModalBilletViewDetailV2Component,
    ModalBilletJurosComponent,
    ModalAlertBilletTaxesComponent,
    ModalTrackingProcessComponent,
    ModalCnabShippingComponent,
    ModalCnabShippingLineComponent,
    ModalSplitDetailsComponent,
    ModalSelfPhotoComponent,
    ModalUnicoValidation,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    ComponentsModule,
    FormsModule,
    IonicModule,
    ModalsRoutingModule,
    ReactiveFormsModule,
    BrMaskerModule,
    UiBsCardsModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTokenModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCBilletReviewModule,
    UiCFavoritesListModule,
    UiCListDataModule,
    UiCInputModule,
    UiCModalModule,
    UiCTextValueModule,
    UiCTokenModule,
    UiCSearchBankModule,
    UiCSearchMccModule,
    UiCTransferReceiptModule,
    UiCPaymentExtractModule,
    UiCPaymentReceiptModule,
    UiCPdfGeneratorModule,
    UiCAddCreditCardFormModule,
    UiCCreditCardDisplayModule,
    UiCPersonalAddressModule,
    UiCCardDocumentModule,
    UiCActiveCardModalFormModule,
    UiCButtonModule,
    UiCBilletReviewChargingModule,
    UiBsEcModule.forRoot({ device: 'WEB', config: environment }),
    QRCodeModule,
    NgxQRCodeModule,
    UiCDeviceModule,
    UiCSendCodeModule,
    UiCSendCodeAccountActivationModule,
    UiCDatePickerModule.forRoot({ environment: 'web' }),
    UiBsDashboardModule.forRoot({ device: 'WEB', config: environment }),
  ],
  entryComponents: [
    ModalTokenComponent,
    ModalCardNotificationComponent,
    ModalSearchMccComponent,
    ModalMigrationSuccessComponent,
  ],
  providers: [
    LoadingService,
  ],
})
export class ModalsModule {}
