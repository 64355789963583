import { StorageService } from '@wlp/ui-bs-oauth';

import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { PartnersService, ResponsibleMeiService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-personal-data',
  templateUrl: './personal-data.modal.html',
  styleUrls: ['./personal-data.modal.scss'],
})
export class PersonalDataModal implements OnInit, OnDestroy {
  navigationBackground: string;
  title: string;
  description: string;

  private destroy$: Subject<null> = new Subject();

  /* public form: any; */

  @Input() partner: any;
  @Input() partnerId: string;
  @Input() step: any;
  /*  @Input() set applyForm(form) {
     if (!!form) this.applyPersonalData(form)
   } */
  @Input() applyForm: any

  @Input() partnerToEdit: any
  @Input() currentRangeValue: number;
  @Input() totalPercentageAvailable: number;
  @Input() isPartnerTypeSelected?: boolean;

  public personalDataForm: FormGroup = null;
  public errorPersonalMessages: any;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private modalActive: ModalController,
    private responsibleService: ResponsibleMeiService,
    private partnerService: PartnersService,
    private prepareFormDevolution: PreparFormDevolutionService,
    private load: LoadingService,
    private storageService: StorageService
  ) {
    moment.locale('pt-BR');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.title = 'Precisamos que nos informe mais alguns dados. Tudo bem?';
    this.description = '';

    // segundo formulario
    this.personalDataForm = this.responsibleService.getFormPersonalData();
    this.errorPersonalMessages = this.responsibleService.getFormBusinessPersonalError();
  }

  ngOnInit(): void {
    this.applyPersonalData(this.applyForm)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRangeChange(event: any) {
    this.currentRangeValue = event
  }

  applyPersonalData(form) {
    this.personalDataForm = this.responsibleService.getFormPersonalData();

    if (!form.birthDay) {
      this.personalDataForm.get('birthDay')
        .setValue('');
    } else {
      this.personalDataForm.get('birthDay')
        .setValue(moment(form.birthDay).format('DD/MM/YYYY'));
    }

    this.personalDataForm.get('marital').setValue(form.marital);
    this.personalDataForm.get('motherName').setValue(form.motherName);
    this.personalDataForm.get('monthlyIncome').setValue(form.monthlyIncome);

    if (this.step != undefined) {
      this.getPersonalDataPartner();
    }
  }

  goBack() {
    this.navCtrl.navigateRoot('account-type');
  }

  convertingDate() {
    const date = this.personalDataForm.get('birthDay').value;
    const toDate = moment(date, 'DD/MM/YYYY').toDate();

    return toDate.toISOString();
  }

  async goContinue(partnerForm) {
    const form = partnerForm.getRawValue()

    form.birthDay = this.convertingDate();

    await this.responsibleService.sendFormBusinessPersonal(form, this.partnerId);


    if (this.step != undefined) {
      this.storageService.get(this.partnerId).then(res => {
        let index = res.steps.indexOf("PERSONAL_DATA");
        if (index > -1) {
          res.steps.splice(index, 1);          
          this.storageService.set(this.partnerId, { id: this.partnerId, steps: res.steps });
        }
      })
    }

    const data = {
      form, 
      currentRangeValue: this.currentRangeValue
    }

    this.modalActive.dismiss(data);
  }

  showTypes(): string[] {
    const arrTypes = [
      'ONBORDING.V2.FORM.LABEL.MASTER',
      'ONBORDING.V2.FORM.LABEL.ATTORNEY',
      'ONBORDING.V2.FORM.LABEL.LEGAL_REPRESENTATIVE',
      'ONBORDING.V2.FORM.LABEL.PARTNER',
      'ONBORDING.V2.FORM.LABEL.SIGNATURE'
    ];
    
    return arrTypes.filter((arr, index) => (this.partner.types[index])).join(',').split(',');
  }

  closeModal() {
    this.modalActive.dismiss(null);
  }

  async getPersonalDataPartner() {
    this.load.show()
    let item = this.step.steps.find(element => {
      if (element.name === "PERSONAL_DATA") {
        return element;
      }
    });    
    if (item != undefined) await this.prepareFormDevolution.prepareFormDevolutionPartners(item.fields, this.personalDataForm);
    this.stopLoad()
  }

  stopLoad() {
    setTimeout(() => {
      this.load.hide()
    }, 2000);
  }
}
