import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { IntroService } from '@wlp/ui-bs-intro';
import { KeycloakService } from '@wlp/ui-bs-login';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { LoadingService } from './../../../../../core/layout/services/loading.service';
import { PopoverComponent } from './popover/popover.component';

@Component({
  selector: 'ui-t-intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
})
export class IntroPage implements OnInit {

  public brand: string;
  public background: string;
  public brandFooter: string;
  public showSocial: boolean;
  public socialYouTube: string;
  public socialFacebook: string;
  public socialLinkedin: string;
  public socialInstagram: string;
  public appleStoreImageLink: string;
  public googleStoreImageLink: string;
  public appleStoreImage: boolean;
  public googleStoreImage: boolean;
  public isMobile: boolean;
  public env: any;
  public realm: string;
  public language: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public isProd: boolean = environment.production;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private introService: IntroService,
    private layoutConfigService: LayoutConfigService,
    private platform: Platform,
    private keycloakService: KeycloakService,
    private translate: UiBsTranslateService,
    public popoverController: PopoverController,
    private loadingService: LoadingService,
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.showSocial = this.layoutConfigService.getConfig('wlTheme.intro.social.show');
    this.brandFooter = this.layoutConfigService.getConfig('wlTheme.intro.footer.brand');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');

    this.socialYouTube = this.layoutConfigService.getConfig('wlTheme.custom.social.youtube');
    this.socialFacebook = this.layoutConfigService.getConfig('wlTheme.custom.social.facebook');
    this.socialLinkedin = this.layoutConfigService.getConfig('wlTheme.custom.social.linkedin');
    this.socialInstagram = this.layoutConfigService.getConfig('wlTheme.custom.social.instagram');

    this.appleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.visible');
    this.appleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.link');

    this.googleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.google.visible');
    this.googleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.google.link');

    this.env = environment.production;
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.language = this.translate.setLanguage(this.langDefault);
    this.introService.cleanStorage();
    this.isMobile = this.platform.is('mobile');
    sessionStorage.removeItem(environment.keyStorage.userEscrow);
    sessionStorage.removeItem(environment.keyStorage.menu);
  }

  public login(): void {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost') || this.validIsIpAddress(document.location.hostname)) {
      redirectUrl += ':' + document.location.port;
    }

    this.keycloakService.login({ redirectUri: redirectUrl + '/app/dashboard', locale: this.langDefault }).then(() => { });
  }

  private validIsIpAddress(hostname: string) {
    const hasNumber = /[0-9]/;
    return hasNumber.test(hostname) && !hostname.includes('v2');
  }

  public createAccount(): void {
    this.navCtrl.navigateRoot('onboarding/account-name');
  }

  public facebookLink() {
    window.open(this.socialFacebook, '_blank');
  }

  public instagramLink() {
    window.open(this.socialInstagram, '_blank');
  }

  public linkedinLink() {
    window.open(this.socialLinkedin, '_blank');
  }

  public youtubeLink() {
    window.open(this.socialYouTube, '_blank');
  }

  public enabledCreateAccount() {
    if (this.isProd) {
      // if (this.realm !== 'urbanobank' && this.realm !== 'psxbank') {
      return false;
      // }
    }

    return true;
  }

  public pathFlag() {
    return this.translate.getFlag(this.language);
  }

  async presentPopover(ev: any) {
    const languageOptions = this.translate.getListLanguage();
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      componentProps: {
        items: languageOptions
      },
      translucent: true
    });
    popover.onDidDismiss().then((res) => {
      if (res.data !== undefined) {
        this.language = res.data.language;
        this.languageChange(res.data.value)
      }
    });
    await popover.present();
  }

  public languageChange(languageData) {
    this.loadingService.show();

    this.langDefault = languageData;
    this.layoutConfigService.setConfig({ wlTheme: { languageDefault: this.langDefault } }, true);
    this.translate.use(this.langDefault);
    this.language = this.translate.setLanguage(this.langDefault);
    setTimeout(() => {
      this.loadingService.hide();
    }, 1500);
  }
}
