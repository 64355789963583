import { ModalAlertComponent } from './../../../modals/modal-alert/modal-alert.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsAlertModule } from '@wlp/ui-bs-alert';
import { UiBsSignupModule } from '@wlp/ui-bs-signup';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAcceptContentModule } from '@wlp/ui-c-accept-content';
import { UiCAccountFormModule } from '@wlp/ui-c-account-form';
import { UiCAccountNameModule } from '@wlp/ui-c-account-name';
import { UiCAccountTypeModule } from '@wlp/ui-c-account-type';
import { UiCAddPartnersModule } from '@wlp/ui-c-add-partners';
import { UiCBoxRadiusModule } from '@wlp/ui-c-box-radius';
import { UiCComercialAgentModule } from '@wlp/ui-c-comercial-agent';
import { UiCComercialAgentV2Module } from '@wlp/ui-c-comercial-agent-v2';
import { UiCCompanyAddressModule } from '@wlp/ui-c-company-address';
import { UiCCompanyDataModule } from '@wlp/ui-c-company-data';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCCreateAccountModule } from '@wlp/ui-c-create-account';
import { UiCCreatePasswordModule } from '@wlp/ui-c-create-password';
import { UiCDocumentTypeModule } from '@wlp/ui-c-document-type';
import { UiCHeaderOnboardingModule } from '@wlp/ui-c-header-onboarding';
import { UiCInputModule } from '@wlp/ui-c-input';
import { UiCPartnerDataModule } from '@wlp/ui-c-partner-data';
import { UiCPartnerListModule } from '@wlp/ui-c-partner-list';
import { UiCPartnersModule } from '@wlp/ui-c-partners';
import { UiCPersonalAddressModule } from '@wlp/ui-c-personal-address';
import { UiCPersonalAddressV2Module } from '@wlp/ui-c-personal-address-v2';
import { UiCPersonalDataModule } from '@wlp/ui-c-personal-data';
import { UiCPersonalDataV2Module } from '@wlp/ui-c-personal-data-v2';
import { UiCProfessionalTypeModule } from '@wlp/ui-c-professional-type';
import { UiCProfessionalTypeV2Module } from '@wlp/ui-c-professional-type-v2';
import { UiCResponsibleMeiDataModule } from '@wlp/ui-c-responsible-mei-data';
import { UiCResponsibleMeiPersonalDataModule } from '@wlp/ui-c-responsible-mei-personal-data';
import { UiCStatusAccountModule } from '@wlp/ui-c-status-account';
import { UiCStatusResponseModule } from '@wlp/ui-c-status-response';
import { UiCTimelineModule } from '@wlp/ui-c-timeline';
import { UiCToggleModule } from '@wlp/ui-c-toggle';
import { UiCUploadFileWebModule } from '@wlp/ui-c-upload-file-web';
import { UiCWarningModule } from '@wlp/ui-c-warning';

import { environment } from '../../../../../../../environments/environment';
import { InterceptService } from '../../../../../../core/intercept/intercept.service';
import { LayoutConfigService } from '../../../../../../core/layout/services/layout-config.service';
import { LoadingService } from '../../../../../../core/layout/services/loading.service';
import { ComponentsModule } from '../../../../../../core/shared/modules/components.module';
import { StepServiceResolver } from '../../../functions/resolver/step-service-resolver';
import { ModalFlowComponent } from '../../../modals/modal-flow/modal-flow.component';
import { StatusAccountPage } from '../../../notifications/status-account/status-account.page';
import { StatusConfirmationPage } from '../../../notifications/status-confirmation/status-confirmation.page';
import { StatusResponsePage } from '../../../notifications/status-response/status-response.page';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { AccountFormPage } from './account-form/account-form.page';
import { AccountNamePage } from './account-name/account-name.page';
import { AccountTypePage } from './account-type/account-type.page';
import { ComercialAgentPage } from './comercial-agent/comercial-agent.page';
import { CompanyAddressPage } from './company-address/company-address.page';
import { CompanyDataPage } from './company-data/company-data.page';
import { ContractPage } from './contract/contract.page';
import { DocumentTypePage } from './document-type/document-type.page';
import { PersonalAddressModal } from './partners/partner-flow/personal-address/personal-address.modal';
import { PersonalDataModal } from './partners/partner-flow/personal-data/personal-data.modal';
import { PersonalUploadModal } from './partners/partner-flow/personal-upload/personal-upload.modal';
import { PartnersPage } from './partners/partners.page';
import { PersonalAddressPage } from './personal-address/personal-address.page';
import { PersonalDataPage } from './personal-data/personal-data.page';
import { ProfessionalTypePage } from './professional-type/professional-type.page';
import { ResponsibleMeiPage } from './responsible-mei/responsible-mei.page';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './sign-up.component';
import { SignUpConfigService } from './sign-up.config.service';

@NgModule({
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    UiCToggleModule,
    ComponentsModule,
    UiCAcceptContentModule,
    ReactiveFormsModule,
    SignUpRoutingModule,
    UiCPersonalDataModule,
    UiCCreateAccountModule,
    UiCComercialAgentModule,
    UiCPersonalAddressModule,
    UiCTimelineModule,
    UiCWarningModule,
    UiCCompanyDataModule,
    UiCPartnersModule,
    UiCCompanyAddressModule,
    UiCContainerBrandModule,
    UiCAddPartnersModule.forRoot({ device: 'WEB', config: environment }),
    UiCBoxRadiusModule,
    UiCProfessionalTypeModule,
    UiBsSignupModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule,
    UiCUploadFileWebModule,
    UiBsAlertModule,
    UiCAccountNameModule,
    UiCAccountTypeModule,
    UiCInputModule,
    UiCAccountFormModule,
    UiCPersonalDataV2Module,
    UiCPersonalAddressV2Module,
    UiCComercialAgentV2Module,
    UiCDocumentTypeModule,
    UiCCreatePasswordModule,
    UiCPartnerListModule.forRoot({ device: 'WEB', config: environment }),
    UiCAcceptContentModule,
    UiCHeaderOnboardingModule,
    UiCPartnerDataModule,
    UiCProfessionalTypeV2Module,
    UiCResponsibleMeiDataModule,
    UiCResponsibleMeiPersonalDataModule,
    UiCStatusResponseModule,
    UiCStatusAccountModule,
  ],
  declarations: [
    SignUpComponent,
    AccountNamePage,
    AccountTypePage,
    ComercialAgentPage,
    CompanyAddressPage,
    CompanyDataPage,
    ContractPage,
    DocumentTypePage,
    PartnersPage,
    PersonalAddressPage,
    PersonalDataPage,
    ProfessionalTypePage,
    AccountFormPage,
    PersonalDataModal,
    PersonalAddressModal,
    PersonalUploadModal,
    ResponsibleMeiPage,
    AcceptTermsComponent,
    StatusResponsePage,
    StatusAccountPage,
    StatusConfirmationPage
  ],
  entryComponents: [
    ModalFlowComponent,
    ModalAlertComponent,
    PersonalDataModal,
    PersonalAddressModal,
    PersonalUploadModal,
  ],
  providers: [
    LayoutConfigService,
    CurrencyPipe,
    LoadingService,
    StepServiceResolver,
    CurrencyPipe,
    LoadingService,
    StepServiceResolver,
    SignUpConfigService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class SignUpModule {}
