import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { AccountsBenefitsService, UserBankInfoService } from '@wlp/ui-bs-dashboard';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { MenuService, UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { uiCModalBankLiquidatorComponent } from '@wlp/ui-c-modal-bank-liquidator';
import { UiCModalChangeAccountComponent } from '@wlp/ui-c-modal-change-account';
import { BrMaskDirective, BrMaskModel } from 'br-mask';
import { ClipboardService } from 'ngx-clipboard';
import { of, Subject } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { environment } from '../../../../../../environments/environment';
import {
  ModalAlertAccessWebMobileDeviceComponent,
} from '../../modals/modal-alert-access-web-mobile-device/access-web-mobile-device.component';
import { PopoverComponent } from './../intro/popover/popover.component';

@Component({
  selector: 'ui-t-manager',
  templateUrl: './cp-manager.page.html',
  styleUrls: ['./cp-manager.page.scss'],
  providers: [BrMaskDirective],
})
export class CpManagerPage implements OnInit {
  public logoMenu: string;
  public logoHeader: string;
  public accountData: any;
  public config: any;
  public flagDisplayCard: boolean;
  public flagDisplayNotification: boolean;
  public flagGrid: boolean;
  public userProfile: UserInfoProfile;
  public menuAside: any;
  public menuAsideFilter: any;
  public listCardGrid: any;
  public backgroundProfile: string;
  public dataQRCode: object;
  public version = ` <span class="text-right text-white">${environment.appVersion} </span> `;
  public isMenuApplication: any;
  public isVisibleFeesAndTariffs = true;
  public realm: string;
  public items: any;
  public inputSearchbar: string;
  public listAccounts: Array<any> = [];
  public language: string;

  private destroy$: Subject<null> = new Subject();

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  screenHeight: number;
  screenWidth: number;
  showSearchBar: boolean;
  public appleStoreImage: string;
  public appleStoreImageLink: string;
  public googleStoreImage: string;
  public googleStoreImageLink: string;
  isMobile: boolean;

  constructor(
    private accountsBenefitsService: AccountsBenefitsService,
    private userBankInfoService: UserBankInfoService,
    protected userInfoService: UserInfoService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private myDataService: UiBsMyDataService,
    public brMask: BrMaskDirective,
    public modalController: ModalController,
    public menuService: MenuService,
    private router: Router,
    private translate: UiBsTranslateService,
    private loadingService: LoadingService,
    public popoverController: PopoverController,
    public platform: Platform,
    private clipboardService: ClipboardService,
    public toastCtrl: ToastController,
  ) {
    /*     this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
        this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
        this.translate.setDefaultLang(this.langDefault); */

    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    // this.userResponse = userBankInfoService.getUserBank();
    this.logoMenu = this.layoutConfigService.getConfig('wlTheme.aside.brand.logo');
    this.logoHeader = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.primary');
    this.backgroundProfile = this.layoutConfigService.getConfig('wlTheme.header.profile.background.image');
    this.dataQRCode = {
      width: 100,
      class: 'qrcode',
      colorLight: '#000000',
      colorDark: '#FFFFFF',
      // colorDark: this.layoutConfigService.getConfig('wlTheme.color.primary'),
      // colorLight: this.layoutConfigService.getConfig('wlTheme.color.secondary'),
    };
  }

  public async ngOnInit() {
    this.config = new BrMaskModel();
    this.config.person = true;
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.language = this.translate.setLanguage(this.langDefault);
    this.translate.setDefaultLang(this.langDefault);
    this.listCardGrid = this.userInfoService.getApplicationAccess();
    if (this.listCardGrid && this.listCardGrid.length > 0) {
      this.isMenuApplication = true;
    }
    this.loadUser();
    this.getScreenSize();

    this.isMobile = this.platform.is('mobile');

    this.appleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.visible');
    this.appleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.link');
    this.googleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.google.visible');
    this.googleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.google.link');

    if (this.isMobile) {
      this.alert('DASHBOARD.MODAL_ACCESS_MOBILE');
    }
  }

  /**
   * Load menu list
   */
  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user: any) => {
        // console.log('userProfile:', user);
        this.userProfile = user;
        this.listAccounts = user.listAccounts;
        this.loadAccountData();
        // this.setInfo();
        this.getMenu();

        if (!localStorage.getItem(environment.keyStorage.userEscrow)) {
          this.setListAccount(this.listAccounts);
        }
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  private async setListAccount(list: Array<any>) {
    if (list.length > 1) {
      setTimeout(() => {
        list.forEach((element) => {
          if (element.typeAccount === 'Usuário Escrow') {
            this.actionChangeAccount(true);
          }
        });
      }, 1500);
    }
  }

  /*
   * Dados do Menu
   */
  public getMenu() {
    const type = this.platform.is('mobile') ? 'web_app' : 'WEB';
    const key = environment.keyStorage.menu;
    sessionStorage.removeItem(environment.keyStorage.menu);
    const key_filter = 'menu-web-filter';

    if (JSON.parse(sessionStorage.getItem(key))) {
      this.menuAside = JSON.parse(sessionStorage.getItem(key));
      this.updateBenefitUrl(this.menuAside);
    } else {
      this.menuService
        .getMenu(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, type)
        .pipe(
          takeUntil(this.destroy$),
          tap((data) => {
            this.menuAside = data;
            this.updateBenefitUrl(this.menuAside);
            sessionStorage.setItem(key, JSON.stringify(this.menuAside));
          }),
          retry(2),
          catchError((_) => {
            return of(null);
          })
        )
        .subscribe();
    }

    if (JSON.parse(sessionStorage.getItem(key_filter))) {
      this.menuAsideFilter = JSON.parse(sessionStorage.getItem(key_filter));
    } else {
      this.menuService
        .getMenuFilter(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, type)
        .pipe(
          takeUntil(this.destroy$),
          tap((data) => {
            this.menuAsideFilter = data;
            sessionStorage.setItem(key_filter, JSON.stringify(this.menuAsideFilter));
          }),
          retry(2),
          catchError((_) => {
            return of(null);
          })
        )
        .subscribe();
    }
  }

  private updateBenefitUrl(menuAside: any) {
    menuAside.forEach(item => {
      if (item.type === 'menu' && Array.isArray(item.url)) {
        item.url.forEach(subItem => {
          if (subItem.url === 'https://www.newvalue.com.br/activate_cpf?uuid=f8855863-3514-46a0-8b8e-25d581464f2e') {
            this.getNewValueUrl(this.userProfile.uuidAccount, subItem);
          }
        });
      }
    });
  }

  public getNewValueUrl(uuidAccount: string, benefitShortcut: any) {
    this.accountsBenefitsService.newValue(uuidAccount).subscribe(
      (response: any) => {
        benefitShortcut.url = response.url;
      },
      (error: any) => {
        console.error('Ocorreu um erro ao buscar a nova URL:', error);
        benefitShortcut.url = "https://www.newvalue.com.br/activate_cpf?uuid=f8855863-3514-46a0-8b8e-25d581464f2e";
      }
    );
  }

  public loadAccountData() {
    this.myDataService.getAccountPaymentPerson(this.userProfile.uuidAccount).subscribe(
      (res) => {
        if (res && res.documentDefault) {
          this.userProfile.document = this.brMask.writeCreateValue(res.documentDefault, this.config);
        }
        if (res && res.fullName) {
          this.userProfile.nameComplete = res.fullName;
        }
        /*  this.setInfo(this.userProfile.documentDefault, res.fullName) */
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagGrid = false;
    this.flagDisplayCard = this.flagDisplayCard ? false : true;
  }

  public onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagGrid = false;
    this.flagDisplayNotification = this.flagDisplayNotification ? false : true;
  }

  public onFlagGrid() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
    this.flagGrid = this.flagGrid ? false : true;
  }

  /**
   * @description Método responsavel por fechar as notificações
   * e aba de perfil ao clicar fora da div dos itens
   */
  public closeNotifications() {
    if (this.flagDisplayNotification) {
      this.flagDisplayNotification = false;
    }
    if (this.flagDisplayCard) {
      this.flagDisplayCard = false;
    }
    if (this.flagGrid) {
      this.flagGrid = false;
    }
  }

  public onLogout(event) {
    if (event) {
      sessionStorage.removeItem(environment.keyStorage.userEscrow);
      sessionStorage.removeItem(environment.keyStorage.menu);
      let redirectUrl = document.location.protocol + '//' + document.location.hostname;

      if (document.location.hostname.includes('localhost')) {
        redirectUrl += ':' + document.location.port;
      }
      this.userInfoService.onLogout(redirectUrl);
    }
  }

  public onChange(event) {
    if (event.blank) {
      window.open(event.url, '_blank');
    } else {
      this.navCtrl.navigateRoot(event.url);
    }
  }

  public myData() {
    this.navCtrl.navigateRoot('/app/my-data');
  }

  public myFeesAndTariffs() {
    this.navCtrl.navigateRoot('/app/fees-tariffs');
  }

  public myLimits() {
    this.navCtrl.navigateRoot('/app/account-limits');
  }

  public goToHome() {
    this.router.navigate(['/app/dashboard']);
  }

  public changeAccount() {
    this.actionChangeAccount();
  }

  public onListMenu(event) {
    this.items = event;
  }

  public goTo(url: string) {
    this.items = null;
    this.inputSearchbar = '';

    if (url.includes('http:') || url.includes('https:')) {
      window.open(url, '_blank');
    } else {
      return this.router.navigate([url]);
    }
  }

  public async actionChangeAccount(userEscrow?: boolean) {
    const modal = await this.modalController.create({
      component: UiCModalChangeAccountComponent,
      cssClass: 'ui-c-modal-change-account-custom-class',
      componentProps: {
        data: this.listAccounts,
        type: 'WEB',
        accountInUse: this.userProfile.numberAccountBank,
        backgroundImage: this.backgroundProfile,
        userEscrow: userEscrow,
      },
      backdropDismiss: !userEscrow,
    });

    modal.onDidDismiss().then((res) => {
      if (!res.data) {
        return;
      } else if (res.data == 'cancel') {
        this.onLogout(true);
      } else {
        const index = this.listAccounts.findIndex((item) => item.uuidAccount.toString() === res.data);

        /* console.log('actionChangeAccount', index, res.data, this.listAccounts[index].userId); */

        if (this.userInfoService.changeAccount(this.listAccounts[index].userId)) {
          setTimeout(() => {
            this.listAccounts.forEach((item) => {
              if (item.accountType === 'Escrow(PJ)' || item.accountType === 'Escrow(PF)') {
                this.setAccountInLocalStorage(environment.keyStorage.userEscrow);
              }
            });
            this.loadUser();
            this.loadingService.show();
            const key = environment.keyStorage.menu;
            const key_filter = 'menu-web-filter';
            sessionStorage.removeItem(key);
            sessionStorage.removeItem(key_filter);

            this.loadingService.hide();
            /* console.log('aaaa') */
            /* this.navCtrl.navigateRoot('/app/dashboard') */
            window.location.href = '/app/dashboard';
          }, 900);
        }
      }
      return;
    });
    return await modal.present();
  }

  private setAccountInLocalStorage(ecwAccount: string) {
    localStorage.setItem(ecwAccount, 'true');
  }

  setInfo() {
    for (let index = 0; index < this.listAccounts.length; index++) {
      let item: any = this.listAccounts[index];
      this.myDataService.getAccountPaymentPerson(item.uuidAccount).subscribe(
        (res) => {
          let obj = this.listAccounts[index];

          if (res && res.documentDefault) {
            this.userProfile.document = this.brMask.writeCreateValue(res.documentDefault, this.config);
          }
          if (res && res.fullName) {
            this.userProfile.nameFirst = res.fullName;
          }

          obj.documentDefault = res.documentDefault;
          obj.fullName = res.fullName;
          this.listAccounts[index] = obj;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  public copyText() {
    if (this.userProfile.codeBank.includes('633')) {
      this.accountData = `
    DADOS DA CONTA 
    
    Nome: ${this.userProfile.nameComplete} 
    CPF/CNPJ: ${this.getDocumentAccount().document} 
    Banco: ${this.userProfile.codeBank} - ${this.userProfile.nameBank} 
    Agência: ${this.userProfile.agencyBank} 
    Conta de Pagamento: ${this.userProfile.numberAccountBank} 

    Obs: Selecione a opção CONTA DE PAGAMENTO no seu banco para transferir para esta conta.
    `;
    } else {
      this.accountData = `
    DADOS DA CONTA 
    
    Nome: ${this.userProfile.nameComplete} 
    CPF/CNPJ: ${this.getDocumentAccount().document} 
    Banco: ${this.userProfile.codeBank} - ${this.userProfile.nameBank} 
    Agência: ${this.userProfile.agencyBank} 
    Conta Corrente: ${this.userProfile.numberAccountBank} 

    `;
    }

    this.clipboardService.copyFromContent(this.accountData);
    this.toastCopy();
  }

  getDocumentAccount() {
    return this.userProfile.listAccounts.find((el) => {
      return this.userProfile.uuidAccount === el.uuidAccount;
    });
  }

  async toastCopy() {
    const toast = await this.toastCtrl.create({
      message: 'Operação realizada com Sucesso! Já pode compartilhar seus dados.',
      duration: 20000,
      position: 'top',
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          handler: () => { },
        },
      ],
    });
    toast.present();
  }

  changeLanguage(event) {
    this.presentPopover(event);
  }

  pathFlag() {
    return this.translate.getFlag(this.language);
  }

  async presentPopover(ev: any) {
    const languageOptions = this.translate.getListLanguage();
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      componentProps: {
        items: languageOptions,
      },
      translucent: true,
    });
    popover.onDidDismiss().then((res) => {
      if (res.data !== undefined) {
        this.language = res.data.language;
        this.languageChange(res.data.value);
      }
    });
    await popover.present();
  }

  async emitLiquidatorBank() {
    const modal = await this.modalController.create({
      component: uiCModalBankLiquidatorComponent,
      cssClass: 'ui-c-modal-liquidator-bank',
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public languageChange(languageData) {
    this.loadingService.show();

    this.langDefault = languageData;
    this.layoutConfigService.setConfig({ wlTheme: { languageDefault: this.langDefault } }, true);
    this.translate.use(this.langDefault);
    this.language = this.translate.setLanguage(this.langDefault);
    setTimeout(() => {
      window.location.reload();
      this.loadingService.hide();
    }, 1500);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 981) {
      this.showSearchBar = false;
    } else {
      this.showSearchBar = true;
    }
  }

  public async alert(message: string) {
    const modalStatus = await this.modalController.create({
      component: ModalAlertAccessWebMobileDeviceComponent,
      cssClass: 'access-web-mobile-device',
      componentProps: {
        message: message,
        appleStoreImage: this.appleStoreImage,
        appleStoreImageLink: this.appleStoreImageLink,
        googleStoreImage: this.googleStoreImage,
        googleStoreImageLink: this.googleStoreImageLink,
        isMobile: true,
      },
      backdropDismiss: true,
    });
    return await modalStatus.present();
  }
}
