import * as moment from 'moment';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { transactionSchedule, TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { take } from 'rxjs/operators';

export interface ListData {
  name: string;
  value: string;
}

@Component({
  selector: 'app-modal-transfer-resume',
  templateUrl: './modal-transfer-resume.component.html',
  styleUrls: ['./modal-transfer-resume.component.scss'],
})
export class ModalTransferResumeComponent implements OnInit {
  public transferData: any;
  public datePickerConfig: any;

  public initialDate: string;
  public formTransfer: FormGroup;

  public listData1: ListData[];
  public listData2: ListData[];

  private newDate: Date;
  private wlName: string;
  private dateTransfer: any;
  private disableDays: string;
  private typeTransfer: string;

  @Input() private permitTransaction: boolean;
  @Input() private datesSchedule: transactionSchedule;
  @Input() private formTransferData: any;
  langDefault: any;
  layoutConfigValue: any;

  constructor(
    public modalController: ModalController,
    private transferService: TransferService,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.newDate = new Date();
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
  }

  ngOnInit() {
    this.loadData();
    this.formTransfer = this.transferService.getFormTransfer();
    this.typeTransfer = this.formTransfer.value.typeTransfer;

    if (this.typeTransfer === 'TED') {
      this.initialDate = moment(this.datesSchedule.dateStart).format('DD/MM/YYYY');
      this.datePickerConfig = {
        editable: false,
        locale: this.langDefault != 'zh' ? this.langDefault : 'zh-hk',
        format: 'DD/MM/YYYY',
        firstDayOfWeek: 'su',
        disableKeypress: true,
        min: moment(this.datesSchedule.dateStart).format('DD/MM/YYYY'),
        max: moment(this.datesSchedule.dateEnd).format('DD/MM/YYYY'),
        isDayDisabledCallback: (Moment) => this.dayDates(Moment),
      };
      return;
    }
    if (this.typeTransfer === 'On-Us') {
      this.initialDate = moment(this.newDate).format('DD/MM/YYYY');
      this.datePickerConfig = {
        editable: false,
        locale: this.langDefault != 'zh' ? this.langDefault : 'zh-hk',
        format: 'DD/MM/YYYY',
        firstDayOfWeek: 'su',
        disableKeypress: true,
        min: moment(this.newDate).format('DD/MM/YYYY'),
        max: moment(this.datesSchedule.dateEnd).format('DD/MM/YYYY'),
      };
      return;
    }
    if (this.typeTransfer === 'TEF') {
      this.initialDate = moment(this.newDate).format('DD/MM/YYYY');
      this.datePickerConfig = {
        editable: false,
        locale: this.langDefault != 'zh' ? this.langDefault : 'zh-hk',
        format: 'DD/MM/YYYY',
        firstDayOfWeek: 'su',
        disableKeypress: true,
        min: moment(this.newDate).format('DD/MM/YYYY'),
        max: moment(this.datesSchedule.dateEnd).format('DD/MM/YYYY'),
      };
      return;
    }
  }

  public async continue() {
    this.formTransfer.get('dateSchedule').setValue(this.dateTransfer);
    await this.transferService.setTransfer(this.formTransfer);
    this.dismiss(true);
  }

  public async dismiss(confirm: boolean) {
    this.modalController.dismiss(confirm);
  }

  public onSelectedDate(dateSelected: any) {
    if (dateSelected === this.initialDate && this.typeTransfer === 'TED') {
      this.dateTransfer = this.datesSchedule.dateStart;
      this.formTransfer.get('scheduled').setValue(!this.permitTransaction);
      return;
    }

    if (dateSelected === this.initialDate && this.typeTransfer === 'On-Us') {
      this.dateTransfer = moment(this.newDate).format('YYYY-MM-DD');
      this.formTransfer.get('scheduled').setValue(false);
      return;
    }

    if (dateSelected != this.initialDate && this.typeTransfer === 'TEF') {      
      this.dateTransfer = moment(this.newDate).format('YYYY-MM-DD');
      this.formTransfer.get('scheduled').setValue(false);
      return;
    }

    if (this.typeTransfer === 'TEF') {      
      this.dateTransfer = moment(this.newDate).format('YYYY-MM-DD');
      this.formTransfer.get('scheduled').setValue(false);
      return;
    }

    if (dateSelected !== this.initialDate) {
      this.dateTransfer = moment(this.newDate).format('YYYY-MM-DD');
      this.formTransfer.get('scheduled').setValue(false);
      return;
    }
  }

  private dayDates(day) {
    this.disableDays = moment(day).format('YYYY-MM-DD');
    if (day._d.getTime() < Date.parse(this.datesSchedule.dateStart)) return true;
    if (day._d.getTime() > Date.parse(this.datesSchedule.dateEnd)) return true;

    if (this.datesSchedule.dateException.indexOf(this.disableDays) == -1) return false;
    else return true;
  }

  private typeDocument(document) {
    if (document.length > 14) {
      return 'CNPJ:';
    } else {
      return 'CPF:';
    }
  }

  private loadData() {
    this.transferService.getTransfer()
      .pipe(
        take(1)
      )
      .subscribe((res) => {
        this.transferData = res;

        this.listData1 = [
          { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.SENDING_BANK', value: this.wlName },
          { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.BENEFICIARY_BANK', value: this.transferData.bank },
        ];

        this.listData2 = [
          { name: this.typeDocument(this.transferData.document), value: this.transferData.document },
          { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.NAME', value: this.transferData.name },
          { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.AGENCY', value: this.transferData.branch },
          { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.ACCOUNT', value: this.transferData.accountNumber },
        ];
      });
  }
}
