import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { interval } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import * as moment from 'moment';
import {environment} from '../../../../../../../environments/environment';
import {StorageService} from '@wlp/ui-bs-oauth';

@Component({
  selector: 'app-device-veracity',
  templateUrl: './device-veracity.page.html',
  styleUrls: ['./device-veracity.page.scss'],
})
export class DeviceVeracityPage implements OnInit {
  public navigationBackground: any;
  public brand: any;
  public userProfile: any;
  public loadCode: boolean;
  public qrData: any;
  public data: string;
  public progressbarValue: number;
  public sub: any;
  public verifySub: any;
  public codeOperation: string = "";

  constructor(
    private layoutConfigService: LayoutConfigService,
    private tokenCodeService: TokenCodeService,
    protected userInfoService: UserInfoService,
    private nabvControler: NavController,
    private storageService: StorageService,
  ) { }
  /*  private translate: UiBsTranslateService, */

  ngOnInit() {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
    this.loadUser();
  }

  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        this.generateQrcodeCodeToken();
        /* this.resetQrCode(); */
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  public generateQrcodeCodeToken() {
    try {
      /*   private readonly OPERATION: string = '1002' */
      this.tokenCodeService.generateQrcodeCodeToken(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, "1002")
        .subscribe(res => {
          this.loadCode = false;
          this.qrData = res;
          this.data = res.data;
          this.resetSub();
          this.resetQrCode();
        });
    } catch (error) {
      console.log(error);
    }
  }

  public resetQrCode() {
    this.progressbarValue = 1;

    this.sub = interval(1500)
      .subscribe((sec) => {
        this.progressbarValue = 1 - (sec * 20) / 1200;

        if (sec > 0 && sec % 5 === 0) {
          this.verifyQrcode();
        }

        if (this.progressbarValue === 0) {
          this.loadCode = true;
          this.sub.unsubscribe();
          this.verifySub.unsubscribe();
          this.generateQrcodeCodeToken();
          this.resetQrCode();
        }
      });
  }

  public resetSub() {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  public verifyQrcode() {
    this.verifySub = this.tokenCodeService.verifyQrcode(this.qrData).subscribe((res: any) => {
      this.verifySub.unsubscribe();
      if (res.isUsed) {
        this.resetSub();
        // TODO: Adicionar armazenamento de check
        const dateExpired = moment(new Date()).add(60, 'minutes').toISOString();

        console.log('inserindo registro de sessao web: ', environment.keyStorage.accessWeb, 'true', true, dateExpired);
        this.storageService.set(environment.keyStorage.accessWeb, 'true', true, dateExpired);
        this.nabvControler.navigateRoot('/app/dashboard');
      }
    });
  }

  public dismissSuccess(isUsed: any, token: any) {
    throw new Error('Method not implemented.');
  }

}
