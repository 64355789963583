import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  Step,
  StepFlowService,
  TypeAccountEnum
} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { Subject } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from '../../../../../../../../environments/environment';
import { LoadingService } from "../../../../../../../core/layout/services/loading.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ui-t-account-type',
  templateUrl: './account-type.page.html',
  styleUrls: ['./account-type.page.scss'],
})
export class AccountTypePage implements AfterContentInit, OnInit, OnDestroy {
  listAccountType: AccountPaymentTypeResponse[] = []
  typeFlow: any;

  physicalPersonIcon: any;
  legalPersonIcon: any;
  meiIcon: any;
  selfEmployedIcon: any;

  dataStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private loadServide: LoadingService,
    private stepsService: StepFlowService,
    private router: Router
  ) {
    this.getImages();
  }

  public async ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);
    await this.service.setNameType(null);

    this.dataStep = await this.stepsService.getSteps(environment, this.router.url, false, true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterContentInit(): void {
    this.loadAccountType();
  }

  async goContinue(event){
    this.loadServide.show();
    await this.service.temporaryTypeAccount(event.data.typeFlow);
    await this.service.setNameType(event.data.name);
    await this.service.setListStep(null);
    await this.stepsService.nextStep(this.navCtrl, this.dataStep);
    this.loadServide.hide();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.dataStep);
  }

  private getImages() {
    this.physicalPersonIcon = this.layoutConfigService.getImagesPages('physical-person');
    this.legalPersonIcon = this.layoutConfigService.getImagesPages('legal-person');
    this.meiIcon = this.layoutConfigService.getImagesPages('mei');
    this.selfEmployedIcon = this.layoutConfigService.getImagesPages('self-employed');
  }

  private async loadAccountType() {
    this.listAccountType = await this.service.loadTypeAccount(environment.wlp);
  } 
}
