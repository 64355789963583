import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { DeviceActionDTO, DeviceStatusEnum, RegisterDeviceDTO, RegistryDeviceService } from '@wlp/ui-bs-login';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalAlertComponent } from '../../../modals/modal-alert/modal-alert.component';

@Component({
  selector: 'ui-t-registered-device',
  templateUrl: './registered-device.page.html',
  styleUrls: ['./registered-device.page.scss'],
})
export class RegisteredDevicePage implements OnInit {
  disabled: boolean = true;
  selectedValue: string;
  deviceInfo: RegisterDeviceDTO;
  public registeredDeviceForm: FormGroup;
  public errorMessages: any;
  hasDevice: boolean = false

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private _registryDeviceService: RegistryDeviceService,
    private _loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.registeredDeviceForm = this._registryDeviceService.createFormRegisteredDevice();
    this.errorMessages = this._registryDeviceService.getErrorMessages();
    this.getRegisteredDevice();
  }

  async getRegisteredDevice() {
    try {
      this._loadingService.show();
      const res: RegisterDeviceDTO = await this._registryDeviceService.getListDevices().toPromise();
      this.deviceInfo = res;   
      this.hasDevice = !!this.deviceInfo;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.hide();
    }
  }

  deviceAction(event: any) {
    let prepare: DeviceActionDTO;
    let deviceStatus: DeviceStatusEnum;

    if (event === 'perdaDoDispositivo') {
      deviceStatus = DeviceStatusEnum.LOSS;
    } else if (event === 'rouboDoDispositivo') {
      deviceStatus = DeviceStatusEnum.THEFT;
    }

    const reasonDescriptionValue = this.registeredDeviceForm.controls.reasonDescription.value || '';

    prepare = {
      uuidUserAccountPaymentDevice: this.deviceInfo.uuid,
      reasonDescription: reasonDescriptionValue,
      deviceStatus: deviceStatus
    };
    this._loadingService.show();
    this._registryDeviceService
      .deviceAction(prepare)
      .pipe(
        tap((data) => {
          this._loadingService.hide();
          this.modalAlert('success', 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.MODAL.SUCCESS_TEXT', false);
        }),
        catchError((_) => {
          this.modalAlert('warning-circle', 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.MODAL.FAILURE_TEXT', false);
          this._loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  public async modalAlert(status: string, message: string, confirmation?: boolean) {
    this._loadingService.hide();
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: message,
        confirmBtn: 'REGISTERED_DEVICE.UI_C_REGISTERED_DEVICE.BUTTONS.CLOSE',
        confirmation: confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then(() => {      
      this.goBack();
    });
    return await modalSuccess.present();
  }

  emmiterActionName(event: any) {
    if (event === 'perdaDoDispositivo' || event === 'rouboDoDispositivo') {
      this.deviceAction(event);
    }
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
}
