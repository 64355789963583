import {AccountLimitPage} from './account-limit/account-limit.page';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {BilletsListPage} from './cash-in/billets-list/billets-list.page';
import {RechargePage} from './cash-in/recharge/recharge.page';
import {CpManagerPage} from './cp-manager.page';
import {DashboardPage} from './dashboard/dashboard.page';
import {DeveloperPage} from './developer/developer.page';
import {MyDataPage} from './my-data/my-data.page';
import {NotificationsPage} from './notifications/notifications.page';
import {ChangePasswordPage} from './change-password/change-password.page';
import {RegisteredDevicePage} from './registered-device/registered-device.page';

const routes: Routes = [
  {
    path: '',
    component: CpManagerPage,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'my-data',
        component: MyDataPage,
      },
      {
        path: 'registered-device',
        component: RegisteredDevicePage,
      },
      {
        path: 'notification',
        component: NotificationsPage,
      },
      {
        path: 'recharge',
        component: RechargePage,
      },
      {
        path: 'list-billets',
        component: BilletsListPage,
      },
      {
        path: 'change-password',
        component: ChangePasswordPage,
      },
      {
        path: 'account-limits',
        component: AccountLimitPage,
      },
      {
        path: 'payment',
        loadChildren: './cash-out/payment/payment.module#PaymentModule',
      },
      {
        path: 'payment-v2',
        loadChildren: './cash-out/payment-v2/payment.module#PaymentV2Module',
      },
      {
        path: 'cards',
        loadChildren: './cards/cards.module#CardsModule',
      },
      {
        path: 'extract',
        loadChildren: './cash-out/extract/extract.module#ExtractModule',
      },
      {
        path: 'billing',
        loadChildren: () => import('./cash-in/billing/billing.module').then((m) => m.BillingModule),
      },
      {
        path: 'charging',
        loadChildren: './cash-out/charging/charging.module#ChargingModule',
      },
      {
        path: 'onboarding-ec',
        loadChildren: './onboarding-ec/onboarding-ec.module#OnboardingEcPageModule',
      },
      {
        path: 'cashback',
        loadChildren: './cashback/cashback.module#CashbackPageModule',
      },
      {
        path: 'pix',
        loadChildren: './pix/pix.module#PixModule',
      },
      {
        path: 'fees-tariffs',
        loadChildren: () => import('./fees-tariffs/fees-tariffs.module').then((m) => m.FeesTariffsPageModule),
      },
      {
        path: 'wallet',
        loadChildren: () => import('./wallet/wallet.module').then((m) => m.WalletModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'dda',
        loadChildren: () => import('./cash-out/dda/dda.module').then((m) => m.DdaModule),
      },
      {
        path: 'recharge-phone',
        loadChildren: () =>
          import('./cash-out/recharge-phone/recharge-phone.module').then((m) => m.RechargePhoneModule),
      },
      {
        path: 'developer',
        component: DeveloperPage,
      },
      {
        path: 'approvals',
        loadChildren: () =>
          import('./cash-out/approvals/approvals.module').then((m) => m.ApprovalsModule),
      },
      {
        path: 'cnab',
        loadChildren: () => import('./cnab/cnab.module').then((m) => m.CnabModule),
      },
      {
        path: 'insurance',
        loadChildren: () => import('./insurance/insurance.module').then((m) => m.InsuranceModule),
      },
      {
        path: 'split',
        loadChildren: () => import('./split/split.module').then((m) => m.SplitModule),
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CpManagerRoutingModule {}
