import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { MaskService } from '@wlp/ui-bs-util';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-unico-validation.component.html',
  styleUrls: ['./modal-unico-validation.component.scss'],
})
export class ModalUnicoValidation {
  @Input() isValidationStep: boolean;
  @Input() unicoValidationStep: string;

  public backgroundHeader: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private maskService: MaskService,
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.backgroundHeader = this.layoutConfigService.getConfig('wlTheme.header.background.image');
  }

  async dismissModal(event) {
    console.log('dismissModal', event);
    this.modalController.dismiss(true);
  }

  public modalIconName() {
    switch (this.unicoValidationStep) {
      case 'PENDENT' :
        return 'fas fa-clock';
    }
  }

  public modalTitleText() {
    switch (this.unicoValidationStep) {
      case 'PENDENT' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.PENDENT.TITLE');
    }
  }

  public modalDescriptionText() {
    switch (this.unicoValidationStep) {
      case 'PENDENT' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.PENDENT.DESCRIPTION');
    }
  }

  public modalStrongText() {
    switch (this.unicoValidationStep) {
      case 'VALIDATION' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.PENDENT.STRONG_TEXT');
      case 'PENDING' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.PENDENT.STRONG_TEXT');
    }
  }

  public modalButtonText() {
    return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.BUTTONS.CLOSE_BUTTON');
  }
}
