import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {LayoutConfigModel} from 'src/app/core/layout/models/layout-config.model';
import {LayoutConfigService} from 'src/app/core/layout/services/layout-config.service';
import {MaskService} from '@wlp/ui-bs-util';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-unico-validation.component.html',
  styleUrls: ['./modal-unico-validation.component.scss'],
})
export class ModalUnicoValidation {
  @Input() validateLiveness: () => Promise<void>;
  @Input() validationStep: string;

  public backgroundHeader: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private maskService: MaskService,
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.backgroundHeader = this.layoutConfigService.getConfig('wlTheme.header.background.image');
  }

  async dismissModal(status: string) {
    this.modalController.dismiss(status);
  }

  async createLiveness() {
    this.modalController.dismiss();
  }

  public modalIconName() {
    switch (this.validationStep) {
      case 'PENDENT' :
      case 'REVALIDATE' :
        return 'fas fa-user-cog';
      case 'IN_AUTOMATIC_ANALYSIS':
      case 'IN_MANUAL_ANALYSIS':
        return 'fas fa-clock';
      case 'APPROVED' :
      case 'APPROVED_MANUAL':
        return 'far fa-check-circle';
      case 'REPROVED':
        return 'fas fa-user-lock';
    }
  }

  public modalTitleText() {
    switch (this.validationStep) {
      case 'PENDENT' :
      case 'REVALIDATE' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.VALIDATION.TITLE');
      case 'IN_AUTOMATIC_ANALYSIS':
      case 'IN_MANUAL_ANALYSIS':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.WAITING_APPROVE_ACCOUNT.TITLE');
      case 'APPROVED' :
      case 'APPROVED_MANUAL':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.ACCOUNT_APPROVED.TITLE');
      case 'REPROVED':
      return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.ACCOUNT_REPROVED.TITLE');
     }
  }

  public modalDescriptionText() {
    switch (this.validationStep) {
      case 'PENDENT' :
      case 'REVALIDATE' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.VALIDATION.DESCRIPTION');
      case 'IN_AUTOMATIC_ANALYSIS':
      case 'IN_MANUAL_ANALYSIS':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.WAITING_APPROVE_ACCOUNT.DESCRIPTION');
      case 'APPROVED' :
      case 'APPROVED_MANUAL':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.ACCOUNT_APPROVED.DESCRIPTION');
      case 'REPROVED':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.ACCOUNT_REPROVED.DESCRIPTION');
    }
  }

  public modalDescriptionTextColor() {
    switch (this.validationStep) {
      case 'PENDENT' :
      case 'REVALIDATE' :
        return 'dark-red';
      case 'IN_AUTOMATIC_ANALYSIS':
      case 'IN_MANUAL_ANALYSIS':
        return 'orange';
      case 'REPROVED':
        return 'gray';
    }
  }

  public modalStrongText() {
    switch (this.validationStep) {
      case 'PENDENT' :
      case 'REVALIDATE' :
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.VALIDATION.STRONG_TEXT');
      case 'IN_AUTOMATIC_ANALYSIS':
      case 'IN_MANUAL_ANALYSIS':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.WAITING_APPROVE_ACCOUNT.STRONG_TEXT');
      case 'REPROVED':
        return this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.STEP.ACCOUNT_REPROVED.STRONG_TEXT');
    }
  }

  public modalButtonText() {
    const btnValidation = this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.BUTTONS.VALIDATE_UNICO');
    const btnClosed = this.translate.applyTranslate('MODALS.MODAL_UNICO_VALIDATION.BUTTONS.CLOSE_BUTTON');
    return this.validationStep === 'PENDENT' || this.validationStep === 'REVALIDATE' ? btnValidation : btnClosed;
  }
}
