import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';

import { LayoutConfigService } from '../../../core/layout/services/layout-config.service';
import { ModalsModule } from './modals/modals.module';
import { CpManagerModule } from './pages/cp-manager/cp-manager.module';
import { IntroPageModule } from './pages/intro/intro.module';
import { DeviceValidationModule } from './pages/onboarding/device-validation/device-validation.module';
import { SignUpModule } from './pages/onboarding/sign-up/sign-up.module';
import { RetryOnboardingModule } from './pages/retry-onboarding/retry-onboarding.module';

@NgModule({
  declarations: [],
  imports: [
    IonicModule,
    SignUpModule,
    ModalsModule,
    IntroPageModule,
    RetryOnboardingModule,
    CpManagerModule,
    DeviceValidationModule,
    NgxMaskIonicModule.forRoot(),
  ],
  providers: [LayoutConfigService],
})
export class SentinelModule {}
