import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InterceptService } from './../../../../../core/intercept/intercept.service';

import { IonicModule } from '@ionic/angular';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCButtonModule } from '@wlp/ui-c-button';
import { UiCHeaderOnboardingModule } from '@wlp/ui-c-header-onboarding';
import { environment } from 'src/environments/environment';
import { DeviceActionPage } from './device-action/device-action.page';
import { DeviceVeracityPage } from './device-veracity/device-veracity.page';
import { RegistryDeviceRoutingModule } from './registry-device-routing.module';
import { RegistryDevicePage } from './registry-device.page';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
  declarations: [
    DeviceActionPage,
    DeviceVeracityPage,
    RegistryDevicePage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RegistryDeviceRoutingModule,
    RouterModule,
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCButtonModule,
    UiCHeaderOnboardingModule,
    NgxQRCodeModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class RegistryDeviceModule {}
